import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  shortAddress: "",
  networkVersion: "",
  point: 0,
  meowdelIds: ["", "1", "4", "101", "500"],
  empIds: ["", "1", "2"],
  galdelIds: [""],
};

const shorten = (addr) => addr.slice(0, 6) + "..." + addr.slice(-4);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    reset: (state) => {
      state.address = initialState.address;
      state.shortAddress = shorten(initialState.address);
      state.networkVersion = initialState.networkVersion;
    },
    initialize: (state, action) => {
      state.address = action.payload.address;
      state.shortAddress = shorten(action.payload.address);
      state.networkVersion = action.payload.networkVersion;
    },
    payoutBy: (state, action) => {
      state.point += action.payload;
    },
    redeemBy: (state, action) => {
      state.point -= action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { reset, initialize, payoutBy, redeemBy } = walletSlice.actions;

export default walletSlice.reducer;
