import React from "react";

const Select = (props) => {
  const { id, children, tokenIds, onChange } = props;

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-4 font-medium text-gray-900 dark:text-gray-400"
      >
        {children}
      </label>
      <select
        id={id}
        onChange={onChange}
        className="text-lg bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {tokenIds.map((tokenId) => (
          <option key={tokenId} className="text-lg">{tokenId}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
