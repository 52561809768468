import LiveAuction from "../components/LiveAuction";
import Wallet from "../components/Wallet";
import LimitedQty from "../components/LimitedQty";

// https://colorhunt.co/palette/f0d9e7ff94cca239ea5c33f6

function Home4() {
  return (
    <>
      <div className="">
        <div className="container bg-[#F0D9E7] mx-auto">
          <Wallet />
          <LiveAuction />
          <LimitedQty />
        </div>
      </div>
    </>
  );
}

export default Home4;
