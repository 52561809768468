import React from "react";

const Banner = () => {
  return (
    <div className="container">
      <div className="">
        <img className="" src="/banner.png" alt="" />
      </div>
    </div>
  );
};

export default Banner;
