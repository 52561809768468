import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavBar = () => {
  const wallet = useSelector((state) => state.wallet);

  let Links = [
    { name: "Sample1", link: "/home1" },
    { name: "Sample2", link: "/home2" },
    { name: "Sample3", link: "/home3" },
    { name: "Sample4", link: "/home4" },
  ];

  return (
    <div className="w-screen shadow-md mt-28">
      <nav className="flex items-center justify-between bg-[#A239EA] pt-2 px-2">
        <ul
          className={
            "flex items-center pb-0 static z-auto left-0 w-auto pl-0 transition-all duration-500 ease-in"
          }
        >
          {Links.map((l) => (
            <li key={l.name} className="ml-8 text-xl my-4">
              <Link
                to={l.link}
                className="text-white font-semibold hover:text-gray-300 duration-500"
              >
                {l.name}
              </Link>
            </li>
          ))}
        </ul>
        {wallet.address ? (
          <div className="flex text-white mr-2">Wallet: {wallet.address}</div>
        ) : null}
      </nav>
    </div>
  );
};

export default NavBar;
