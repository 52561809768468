import Banner from "../components/Banner";

// https://colorhunt.co/palette/f0d9e7ff94cca239ea5c33f6

function Home1() {
  return (
    <>
      <div className="">
        <div className="container bg-[#F0D9E7] mx-auto">
          <Banner />
        </div>
      </div>
    </>
  );
}

export default Home1;
