import React from "react";
import { useSelector } from "react-redux";

const Wallet = () => {
  const { shortAddress, point, meowdelIds, empIds } = useSelector(
    (state) => state.wallet
  );

  return (
    <div className="text-gray-900 text-sm p-5 w-1/2">
      <h1 className="mb-2 text-lg text-gray-700 font-semibold">Wallet</h1>
      <div className="flex flex-row">
        <div className="">
          <div className="flex flex-row items-center mb-1">
            <p className="w-32">Wallet Address:</p>
            <p className="font-normal">{shortAddress}</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="w-32">Balance:</p>
            <p className="font-normal">{point} pt</p>
          </div>
        </div>
        <div className="ml-10 font-normal">
          <div className="flex flex-row space-x-2 items-center mx-auto">
            <img
              className="rounded-full shadow-md"
              src="https://via.placeholder.com/48"
              alt=""
            />
            <span className="text-lg pr-5">x {meowdelIds.length - 1}</span>
            <img
              className="rounded-full shadow-md"
              src="https://via.placeholder.com/48"
              alt=""
            />
            <span className="text-lg">x {empIds.length - 1}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
  