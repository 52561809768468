import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TopBar from "./components/TopBar";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home1 from "./pages/Home1";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import Home4 from "./pages/Home4";
import Home5 from "./pages/Home5";
import Home6 from "./pages/Home6";

function App() {
  return (
    <Router>
      <div>
        <TopBar />
        <NavBar />
        <div className="grid grid-cols-1 place-content-center">
          <Switch>
            <Route path="/home1">
              <Home1 />
            </Route>
            <Route path="/home2">
              <Home2 />
            </Route>
            <Route path="/home3">
              <Home3 />
            </Route>
            <Route path="/home4">
              <Home4 />
            </Route>
            <Route path="/home5">
              <Home5 />
            </Route>
            <Route path="/home6">
              <Home6 />
            </Route>
            <Route exact path="/">
              <Home1 />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
