import React, { useMemo } from "react";
import Table from "../components/Table"; // new

const initialPayouts = [
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-01" },
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-02" },
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-03" },
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-04" },
  { meowdel: 101, emp: 2, point: 100, timestamp: "2022-04-04" },
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-05" },
  { meowdel: 101, emp: 2, point: 100, timestamp: "2022-04-05" },
  { meowdel: 500, emp: 0, point: 50, timestamp: "2022-04-05" },
];

const getData = () => {
  return initialPayouts;
};

const PayoutList = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Meowdel #",
        accessor: "meowdel",
      },
      {
        Header: "EMP #",
        accessor: "emp",
      },
      {
        Header: "Points Paid",
        accessor: "point",
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
      },
    ],
    []
  );

  const data = useMemo(() => getData(), []);

  return (
    <>
      <div className="w-1/2 p-5">
        <div className="container bg-[#F0D9E7] mx-auto">
          <h1 className="mb-2 text-lg text-gray-700 font-semibold">Payouts</h1>
          <div className="text-gray-900 pt-4">
            <Table columns={columns} data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutList;
