import CouponList from "../components/CouponList";
import PayoutList from "../components/PayoutList";
import StakeList from "../components/StakeList";
import Wallet from "../components/Wallet";

// https://colorhunt.co/palette/f0d9e7ff94cca239ea5c33f6

function Home2() {
  return (
    <>
      <div className="">
        <div className="container bg-[#F0D9E7] mx-auto">
          <Wallet />
          <div className="flex">
            <StakeList />
            <PayoutList />
          </div>
          <CouponList />
        </div>
      </div>
    </>
  );
}

export default Home2;
