import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reset, initialize } from "../features/wallet/walletSlice";

const Topbar = () => {
  const wallet = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const connect = async () => {
    const [address] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    dispatch(
      initialize({
        address,
        networkVersion: window.ethereum.networkVersion,
      })
    );

    setError("");
  };

  const handleConnect = (e) => {
    if (window.ethereum === undefined) {
      setError("MetaMask not installed");
      return;
    }

    connect();

    window.ethereum.on("accountsChanged", ([newAddress]) => {
      console.log("accountsChanged");
      connect();
    });

    window.ethereum.on("chainChanged", ([networkId]) => {
      console.log("chainChanged");
      dispatch(reset());
    });
  };

  return (
    <div>
      <div className="w-screen absolute top-0 left-0 z-100">
        <section className="sticky bg-[#5C33F6] p-5">
          <div className="w-full">
            <div className="flex items-center justify-between">
              <div className="flex flex-row items-center">
                <img className="h-20 w-20 mx-3" src="/meowlogo.png" alt="" />
                <div className="text-5xl font-bold tracking-tight text-gray-100">
                  Meowdel Marketplace
                </div>
              </div>
              <div className="flex justify-center text-lg text-gray-600">
                <div>
                  {wallet.address ? (
                    <p className="hidden text-white font-semibold">{wallet.address}</p>
                  ) : (
                    <button
                      onClick={(e) => handleConnect(e)}
                      className="text-gray-100 bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-lg px-10 py-2.5 text-center"
                    >
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Topbar;
