import React, { useMemo } from "react";
import Table from "../components/Table"; // new

const initialStakes = [
  { meowdel: 1, emp: 1, point: 100, timestamp: "2022-04-13 15:38:11.288" },
  { meowdel: 101, emp: 2, point: 100, timestamp: "2022-04-13 15:39:11.288" },
  { meowdel: 500, emp: 0, point: 50, timestamp: "2022-04-14 15:39:11.288" },
];

const getData = () => {
  return initialStakes;
};

const PayoutList = () => {
  const handleUnstake = (e, row) => {
    // console.log(e, row);
    // const newArray = stakes.map(a => ({...a}));
    // newArray.splice(index, 1);
    // setStakes(newArray);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Meowdel #",
        accessor: "meowdel",
      },
      {
        Header: "EMP #",
        accessor: "emp",
      },
      {
        Header: "Reward Points",
        accessor: "point",
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
      },
      {
        Header: "Unstake",
        Cell: ({ row }) => 
        (
          <div className="d-flex justify-center">
            <button
              key={row.id}
              type="button"
              onClick={(e) => handleUnstake(e, row)}
              className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg px-3 py-2.5 text-center"
            >
              Unstake
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => getData(), []);

  return (
    <>
      <div className="w-1/2 p-5">
        <div className="container bg-[#F0D9E7] mx-auto">
          <h1 className="mb-2 text-lg text-gray-700 font-semibold">
            List of Stakes
          </h1>
          <div className="text-gray-900 pt-4">
            <Table columns={columns} data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutList;
