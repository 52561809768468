import React, { useMemo } from "react";
import Table from "../components/Table"; // new

const initialCoupons = [
  {
    couponCode: "NLLFAMP",
    name: "Norton 360 Identity Protective Software",
    salesType: 1,
    point: 500,
    qty: 1,
    timestamp: "2022-04-13 15:38:11.288",
  },
  {
    couponCode: "STAYSAFE",
    name: "Get 50% Off with Parallels Promo Code",
    salesType: 0,
    point: 400,
    qty: 1,
    timestamp: "2022-04-14 15:38:11.288",
  },
  {
    couponCode: "UDEAFFSM04",
    name: "Learn social media marketing courses at 95% OFF",
    salesType: 0,
    point: 300,
    qty: 1,
    timestamp: "2022-04-15 15:38:11.288",
  },
];

const getData = () => {
  return initialCoupons;
};

const CouponList = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Coupon Code",
        accessor: "couponCode",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sales Type",
        accessor: "salesType",
      },
      {
        Header: "Points Used",
        accessor: "point",
      },
      {
        Header: "Qty.",
        accessor: "qty",
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        // Filter: SelectColumnFilter, // new
        // filter: "includes",
      },
    ],
    []
  );

  const data = useMemo(() => getData(), []);

  return (
    <>
      <div className="p-5">
        <div className="container bg-[#F0D9E7] mx-auto">
          <h1 className="mb-2 text-lg text-gray-700 font-semibold">
            Redeemed Coupons
          </h1>
          <div className="text-gray-900 pt-4">
            {/* <div className="mt-6"> */}
            <Table columns={columns} data={data} />
            {/* </div> */}
            {/* </main> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponList;
