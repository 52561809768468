import Stake from "../components/Stake";
import Wallet from "../components/Wallet";
import StakeList from "../components/StakeList";

// https://colorhunt.co/palette/f0d9e7ff94cca239ea5c33f6

function Home3() {
  return (
    <>
      <div className="">
        <div className="container bg-[#F0D9E7] mx-auto">
          <Wallet />
          <Stake />
          <StakeList />
        </div>
      </div>
    </>
  );
}

export default Home3;
