import React, { useMemo } from "react";
import Table, { SelectColumnFilter } from "../components/Table"; // new

import LiveAuction from "../components/LiveAuction";
import Wallet from "../components/Wallet";
import LimitedQty from "../components/LimitedQty";

// https://colorhunt.co/palette/f0d9e7ff94cca239ea5c33f6

const getData = () => {
  return [
    {
      couponCode: "NLLFAMP",
      name: "Norton 360 Identity Protective Software",
      salesType: 1,
      point: 500,
      qty: 1,
      timestamp: "2022-04-13 15:38:11.288",
    },
    {
      couponCode: "STAYSAFE",
      name: "Get 50% Off with Parallels Promo Code",
      salesType: 0,
      point: 400,
      qty: 1,
      timestamp: "2022-04-14 15:38:11.288",
    },
    {
      couponCode: "UDEAFFSM04",
      name: "Learn social media marketing courses at 95% OFF",
      salesType: 0,
      point: 300,
      qty: 1,
      timestamp: "2022-04-15 15:38:11.288",
    },
  ];
};

function Home6() {
  const columns = useMemo(
    () => [
      {
        Header: "Coupon Code",
        accessor: "couponCode",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sales Type",
        accessor: "salesType",
      },
      {
        Header: "Points Used",
        accessor: "point",
      },
      {
        Header: "Qty.",
        accessor: "qty",
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        // Filter: SelectColumnFilter, // new
        // filter: "includes",
      },
    ],
    []
  );

  const data = useMemo(() => getData(), []);

  return (
    <>
      <div className="">
        <div className="container bg-[#F0D9E7] mx-auto">
          <div className="min-h-screen bg-gray-100 text-gray-900 px-6 pt-4">
            {/* <div className="mt-6"> */}
            <Table columns={columns} data={data} />
            {/* </div> */}
            {/* </main> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home6;
