import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { payoutBy } from "../features/wallet/walletSlice";

import Select from "./Select";

// const initialMeowdelIds = ["", "1", "4", "101", "500"];
// const initialEmpIds = ["", "1", "2"];
const initialReward = {
  meowdel: 0,
  emp: 0,
  point: 0,
};

const Stake = () => {
  const { meowdelIds, empIds } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const [availableMeowdelIds, setAvailableMeowdelIds] = useState(meowdelIds);
  const [availableEmpIds, setAvailableEmpIds] = useState(empIds);
  const [reward, setReward] = useState(initialReward);

  const parseValue = (value) => (isNaN(value) ? 0 : parseInt(value));

  const removeValueFromArray = (arr, value) => arr.filter((e) => e !== value);

  // const cloneArray = (arr) => arr.map(e => e);

  const calculateReward = (r) => {
    const meowdelId = parseValue(r["meowdel"]);
    const empId = parseValue(r["emp"]);

    if (meowdelId > 0 && empId > 0) {
      r["point"] = 100;
    } else if (meowdelId > 0 && empId === 0) {
      r["point"] = 50;
    } else if (meowdelId === 0) {
      r["point"] = 0;
    }

    r["meowdel"] = meowdelId;
    r["emp"] = empId;

    return r;
  };

  const handleChange = (e) => {
    let newReward = {
      ...reward,
      [e.target.id]: parseInt(e.target.value),
    };

    newReward = calculateReward(newReward);

    setReward(newReward);
    console.log(newReward);
  };

  const handleStake = (e) => {
    e.preventDefault();
    const { meowdel, emp, point } = reward;
    console.log(`calling stake(${meowdel}, ${emp}, ${point})`);

    setAvailableMeowdelIds(removeValueFromArray(availableMeowdelIds, meowdel.toString()));
    setAvailableEmpIds(removeValueFromArray(availableEmpIds, emp.toString()));
    setReward(initialReward);

    dispatch(payoutBy(point));
  };

  return (
    <div className="container">
      <form className="p-5">
        <div className="flex flex-row space-x-4 text-lg">
          <div>
            <Select
              id={"meowdel"}
              tokenIds={availableMeowdelIds}
              onChange={(e) => handleChange(e)}
            >
              Select your Meowdel
            </Select>
            <div>
              <img
                className="my-4 rounded-lg border border-1 border-gray-400 shadow-md"
                src="https://via.placeholder.com/256"
                alt=""
              />
              <p>Meowdel #{reward["meowdel"] === 0 ? "" : reward["meowdel"]}</p>
            </div>
          </div>

          <p className="mt-14 text-center">+</p>

          <div>
            <Select
              id={"emp"}
              tokenIds={availableEmpIds}
              onChange={(e) => handleChange(e)}
            >
              Select your EMP
            </Select>
            <div>
              <img
                className="my-4 rounded-lg border border-1 border-gray-400 shadow-md"
                src="https://via.placeholder.com/256"
                alt=""
              />
              <p>EMP #{reward["emp"] === 0 ? "" : reward["emp"]}</p>
            </div>
          </div>

          <p className="mt-14 text-center">=</p>

          <div>
            <label
              htmlFor="reward"
              className="block mb-4 font-medium text-gray-900 dark:text-gray-400"
            >
              Reward (pt per day)
            </label>

            <p
              id="reward"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {reward["point"]}
              <span> pt</span>
            </p>
          </div>
        </div>
        <button
          type="button"
          onClick={(e) => handleStake(e)}
          disabled={reward["point"] === 0}
          className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-lg px-10 py-2.5 text-center mt-6 mr-2 mb-2"
        >
          Stake
        </button>
      </form>
    </div>
  );
};

export default Stake;
