import React from "react";

const LimitedQtyCard = () => {
  return (
    <div className="w-72 rounded-2xl border border-purple-300 bg-purple-100 mx-auto mt-8 mb-16">
      <img
        className="mx-auto mt-6 rounded-2xl border border-1 border-gray-400 shadow-md"
        src="https://via.placeholder.com/250"
        alt=""
      />
      <div className="mx-5 mb-8 mt-4 px-2">
        <div className="mb-4 text-black">
          <a href="/" className="text-ellipsis font-semibold">
            3D Space Rocket With Smoke Premium
          </a>
        </div>
        <div className="text-sm text-gray-500">
          Qty
          <div>
            <span className="font-semibold text-black">200</span>
            <span>pt</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimitedQtyCard;
