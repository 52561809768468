import React from "react";

const AuctionCard = () => {
  return (
    <div className="z-1 w-72 rounded-2xl border border-purple-300 bg-purple-100 mx-auto mt-8 mb-16">
      <img
        className="mx-auto mt-10 rounded-2xl border border-1 border-gray-400 shadow-md"
        src="https://via.placeholder.com/250"
        alt=""
      />
      <div className="flex w-1/2 -translate-y-[265px] translate-x-1/2 justify-center rounded-lg bg-gradient-to-r from-violet-500 to-fuchsia-500 text-center text-white">
        09:52:12:01
      </div>
      <div className="w-10 -translate-y-9 translate-x-52 rounded bg-indigo-600 px-1 text-center text-sm text-white">
        New
      </div>
      <div className="mx-5 mb-8 -mt-4 px-2">
        <div className="mb-4 text-black">
          <a href="/" className="text-ellipsis font-semibold">
            3D Space Rocket With Smoke Premium
          </a>
        </div>
        <div className="text-sm text-gray-500">
          Current Bid
          <div>
            <span className="font-semibold text-black">200</span>
            <span>pt</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
