import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import LimitedQtyCard from "./LimitedQtyCard";

const dataLiveAuction = [
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga1,
    name: "Daniel M.Bivens",
    price: "201",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga2,
    name: "Daniel M.Bivens",
    price: "202",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga3,
    name: "Daniel M.Bivens",
    price: "203",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga4,
    name: "Daniel M.Bivens",
    price: "204",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga1,
    name: "Daniel M.Bivens",
    price: "205",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga2,
    name: "Daniel M.Bivens",
    price: "206",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga3,
    name: "Daniel M.Bivens",
    price: "207",
    priceChange: "$32.420",
  },
  {
    img: "https://via.placeholder.com/250",
    title: "3D Space Rocket With Smoke Premium",
    tags: "New",
    // imgAuthor: imga4,
    name: "Daniel M.Bivens",
    price: "208",
    priceChange: "$32.420",
  },
];

const LimitedTimeQty = (props) => {
  //   const data = props.data;

  const [data, setData] = useState(dataLiveAuction);

  return (
    <section className="">
      <div className="container">
        <div className="w-full p-2">
          <div className="flex items-center">
            <div className="relative inline-flex m-3">
              <span className="flex h-8 w-8">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-8 w-8 bg-indigo-500"></span>
                <span className="absolute inline-flex rounded-full h-6 w-6 bg-white translate-x-1 translate-y-1"></span>
                <span className="absolute inline-flex rounded-full h-4 w-4 bg-indigo-500 translate-x-2 translate-y-2"></span>
              </span>
            </div>
            <h2 className="text-3xl ml-1">Latest Collections</h2>
          </div>
          {/* <div className="swiper-wrapper live-auc flex-wrap pl-[121px] pr-[80px]"> */}
          <div className="flex-wrap">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={10}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
              }}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <LimitedQtyCard />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LimitedTimeQty;
